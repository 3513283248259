import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

const DropoffDetailDialog = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        Drop-Off Details
      </DialogTitle>
      <DialogContent>
        <DialogContentText component='div'>
          <Typography varient='p' component='p' gutterBottom>
            Please drop off your sponsored Angel wishes at the location you selected in your application. You will need to present your QR code or confirmation number at the time of drop-off. You can find your QR code or confirmation number in the confirmation email that you should have received after submitting your application.

            <br />
            <br />
            To make any changes to your drop-off location, don't hesitate to contact us directly at {' '}
            <Link
              color='primary'
              variant='primary'
              href='mailto:angeltree@ufvsus.ca'
            >
              angeltree@ufvsus.ca
            </Link>{' '}
            or call{' '}
            <Link color='primary' variant='primary' href='tel:6048644613'>
              604-557-5361
            </Link>
            .
          </Typography>

          <br />

          <Typography variant='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Starts:</span> Tuesday, November 14, 2023 <span style={{ fontWeight: 'bold' }}>Ends:</span> Monday, December 4, 2023
          </Typography>
          <br />

          {/* Abbotsford Dropoff */}
          <Typography variant='p' component='p' fontWeight='bold'>Abbotsford Drop-off:</Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/abbotsford-campus/'>Student Union Society, Building S Room S1109</Link>
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Nov 14 - 17, Nov 20 - 24, Nov 27 - Dec 1, Dec 4
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Days:</span> Monday to Friday
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span> 9:00 am to 4:00 pm
          </Typography>

          <br />
          <Typography varient='p' component='p' fontWeight='bold'>
            Evening Drop-Off:
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/abbotsford-campus/'>Abbotsford (Building S, Room S1109)</Link>
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Dec 4
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span> 9:00 am to 8:00 pm
          </Typography>

          <br />

          {/* Chilliwack dropoff */}
          <Typography variant='p' component='p' fontWeight='bold'>Chilliwack Drop-off:</Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/chilliwack-campus/'>CEP Campus, Student Services, Building A, Room A1318</Link>
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Nov 14 - 17, Nov 20 - 24, Nov 27 - Dec 1, Dec 4
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Days:</span> Monday to Friday
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span> 9:00 am to 4:00 pm
          </Typography>

          <br />
          <Typography varient='p' component='p' fontWeight='bold'>
            Evening Drop-Off:
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Location:</span> <Link color='primary' variant='primary' href='https://www.ufv.ca/maps/abbotsford-campus/'>CEP Campus, Student Union Office, Building A, Room A1422</Link>
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Dates:</span> Dec 4
          </Typography>
          <Typography varient='p' component='p'>
            <span style={{ fontWeight: 'bold' }}>Hours:</span> 4:00 pm to 8:00 pm
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropoffDetailDialog;
